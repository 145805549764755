.missed-call {
  margin-right: 0.833333rem;
  border: 0.027778rem solid #ff2525;
  padding: 0.277778rem;
  border-radius: 0.277778rem;
}

.missed-call b {
  color: #ff2525;
  margin-right: 0.277778rem;
}

.hbb-login-status {
  margin-right: 0.277778rem;
}

.hbb-login-status b {
  display: inline-flex;
  width: 0.222222rem;
  height: 0.222222rem;
  border-radius: 50%;
  margin-right: 0.138889rem;
}

.hbb-login-status b.down {
  background: #fd3f3f;
}

.hbb-login-status b.pedding {
  background: #f7cd1e;
}

.hbb-login-status b.up {
  background: #58cf25;
}

.hbb-login-status span {
  font-size: 0.333333rem;
}

.hbb-login-status span.down {
  color: #fd3f3f;
}

.hbb-login-status span.pedding {
  color: #f7cd1e;
}

.hbb-login-status span.up {
  color: #58cf25;
}