.hbb-vacation-calendar-modal {
  display: flex;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 0.111111rem;
  flex-direction: column;
}

.hbb-vacation-calendar-modal.needScroll {
  max-height: 13.888889rem;
  overflow: auto;
}

.hbb-vacation-calendar-modal .hbb-calendar-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.555556rem;
  margin: 0.138889rem 0;
}

.hbb-vacation-calendar-modal .con {
  max-height: 11.111111rem;
  overflow-y: auto;
}

.hbb-vacation-calendar-modal .con .calendar-day {
  display: flex;
  position: relative;
  height: calc(100% + 0.444444rem);
  width: calc(100% + 0.444444rem);
  margin-left: -0.222222rem;
  margin-top: -0.222222rem;
  flex-direction: column;
}

.hbb-vacation-calendar-modal .con .calendar-day .day {
  position: absolute;
  left: 0.222222rem;
  top: 0.222222rem;
  display: flex;
  width: 100%;
  z-index: 99;
}

.hbb-vacation-calendar-modal .con .calendar-day .day.dayFFF {
  color: #fff;
}

.hbb-vacation-calendar-modal .con .calendar-day .day-am,
.hbb-vacation-calendar-modal .con .calendar-day .day-pm {
  display: flex;
  height: 50%;
  width: 100%;
  left: 0;
  align-items: center;
  justify-content: center;
}

.hbb-vacation-calendar-modal .con .calendar-day .day-am.deselected,
.hbb-vacation-calendar-modal .con .calendar-day .day-am.isCancel,
.hbb-vacation-calendar-modal .con .calendar-day .day-pm.deselected,
.hbb-vacation-calendar-modal .con .calendar-day .day-pm.isCancel {
  background-color: #f2f2f2;
  color: #333;
  text-decoration: line-through;
}

.hbb-vacation-calendar-modal .con .calendar-day .day-am.selected,
.hbb-vacation-calendar-modal .con .calendar-day .day-pm.selected {
  background-color: #1296db;
  color: #fff;
  text-decoration: none;
}

.hbb-vacation-calendar-modal .con .calendar-day.allSelected .day-pm,
.hbb-vacation-calendar-modal .con .calendar-day.allSelected .day-am {
  background-color: #1296db;
}

.hbb-vacation-calendar-modal .con .calendar-day.allSelected .day {
  color: #fff;
}

.hbb-vacation-calendar-modal .con .calendar-day.allSelected .day-am .all {
  color: #fff;
  transform: translateY(0.611111rem);
}

.hbb-vacation-calendar-modal .con .calendar-day.allSelected .day-pm {
  color: #1296db;
}

.hbb-vacation-calendar-modal .con .calendar-day.allDeselected .day-pm,
.hbb-vacation-calendar-modal .con .calendar-day.allDeselected .day-am {
  background-color: #f2f2f2;
}

.hbb-vacation-calendar-modal .con .calendar-day.allDeselected .day {
  color: #333;
}

.hbb-vacation-calendar-modal .con .calendar-day.allDeselected .day-am .allCancel {
  transform: translateY(0.611111rem);
}

.hbb-vacation-calendar-modal .con .calendar-day.allDeselected .day-pm {
  color: #f2f2f2;
}

.hbb-vacation-calendar-modal .hbb-calendar-footer {
  display: flex;
  width: 100%;
  padding: 0 0.555556rem 0 0.555556rem;
}

.hbb-vacation-calendar-modal .hbb-calendar-footer .el-button {
  width: 100%;
}