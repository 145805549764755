.fade-enter-active,
.fade-leave-active {
  transition: opacity .1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.text-center {
  background-color: #fff;
  height: 100%;
  text-align: left;
  padding-left: 0.555556rem;
  padding-bottom: 0.555556rem;
  margin-right: 0.277778rem;
}

.text-center .text {
  display: inline-block;
  font-size: 0.555556rem;
  padding-top: 0.555556rem;
}

.text-center .page-table-btn {
  margin-top: 0.277778rem;
}

.crop {
  font-size: 0.388889rem;
  color: #535b6c;
  padding-left: 0.694444rem;
  line-height: 1.666667rem;
}

.back-btn {
  display: inline-block;
  margin-top: 0.277778rem;
  border: 0.027778rem solid #1a7ef8;
  color: #1a7ef8;
  font-size: 0.388889rem;
  border-radius: 0.111111rem;
  padding: 0.083333rem 0.277778rem;
  cursor: pointer;
}

.back-btn:hover {
  border: 0.027778rem solid #1a7ef8;
  background-color: #1a7ef8;
  color: #fff;
}

.nohavePermission {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.nohavePermission span {
  font-size: 7.777778rem;
}

.nohavePermission p {
  font-size: 0.833333rem;
  color: #666;
}

.app-main-area {
  height: calc(100vh - 1.944444rem);
  background-color: #fff;
  padding-bottom: 1.111111rem;
  position: relative;
}

.app-main-area .app-content {
  height: calc(100vh - 3.055556rem);
  overflow-x: auto;
}

.app-main-area .footer {
  width: 100%;
  background-color: #f2f2f2;
  color: #70757a;
  font-size: 0.388889rem;
  line-height: 1.111111rem;
  text-align: center;
  position: absolute;
  bottom: 0;
}