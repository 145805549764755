.account-modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.277778rem 0;
  cursor: pointer;
}

.account-modal .top {
  display: flex;
  width: 100%;
  margin-bottom: 0.555556rem;
  justify-content: space-between;
  padding: 0 0.277778rem;
}

.account-modal .top span {
  font-size: 0.5rem;
  font-weight: bold;
}

.account-modal .con {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0.277778rem;
}

.account-modal .con .icon-edit-opt {
  color: #2d75d4;
}

.account-modal .con p {
  font-size: 0.444444rem;
  padding-left: 0.277778rem;
  margin-bottom: 0.555556rem;
}

.account-modal .con .span-right {
  display: flex;
  justify-content: space-between;
}

.account-modal .con div {
  float: left;
  width: 100%;
  border-bottom: 0.027778rem solid #ddd;
}

.account-modal .con div span {
  float: left;
  width: 50%;
  margin-bottom: 0.416667rem;
  padding-left: 0.555556rem;
}

.account-modal .con div span:last-child b {
  float: right;
}

.account-modal .bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.416667rem;
  font-size: 0.5rem;
}

.account-modal .bottom b {
  font-size: 0.722222rem;
  margin-left: 0.138889rem;
  margin-right: 0.277778rem;
}