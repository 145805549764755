.modify-role {
  cursor: pointer;
  padding: 0.277778rem 0;
}

.modify-role .top {
  display: flex;
  width: 100%;
  margin-bottom: 0.555556rem;
  justify-content: space-between;
}

.modify-role .top span {
  font-size: 0.5rem;
  font-weight: bold;
}

.modify-role .con {
  padding: 0 0.555556rem;
}

.modify-role .con .role-title {
  margin-right: 0.277778rem;
}

.modify-role .bottom {
  display: flex;
  width: 100%;
  margin-top: 0.277778rem;
  justify-content: center;
}