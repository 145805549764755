.agent-offline-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.agent-offline-box p {
  margin: 0.555556rem 0;
  font-size: 0.444444rem;
  text-align: center;
}

.agent-offline-box .el-button {
  width: 2.777778rem;
  margin: 0.555556rem auto 0 auto;
}

.vertical-medium-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vertical-medium-dialog div {
  display: flex;
  margin: 0.277778rem 0;
  align-items: center;
}

.vertical-medium-dialog div span {
  width: 2.777778rem;
}